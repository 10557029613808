<template>
  <v-app>
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        justify="center"
      >
        <v-col lg="3">
          <center>
            <v-img
              :src="require('@/assets/img/logo.png')"
              max-width="250"
            />
          </center>
          <v-card
            class="rounded-xl"
            elevation="24"
          >
            <v-card-title class="py-6">
              Verificacion de Email
            </v-card-title>
            <v-card-text class="title-font-size">
              <v-row class="mb-3">
                <v-col>
                  Tu e-mail ha sido verificado, ya puedes iniciar sesion
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  export default {
  }
</script>
<style scoped>
  .title-font-size{
    font-size: 18px;
  }
</style>
